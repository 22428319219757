body {
    height: 100%;
    margin: 0;
    padding: 0; 
    background: #EFF2F5;
  }
  
  #root {
    height: 100%;
  } 
  
  a {
    color: #00A1E4 !important;
    text-decoration: none;
  }
  a:visited {
    color: inherit;
  }  
  a:hover {
    text-decoration: underline;
  }
  
  @-webkit-keyframes GradientAnimator {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  @-moz-keyframes GradientAnimator {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  @-o-keyframes GradientAnimator {
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  @keyframes GradientAnimator { 
    0%{background-position:0% 50%}
    50%{background-position:100% 51%}
    100%{background-position:0% 50%}
  }
  